@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Lao:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");

$primary: #f0b90b;
$secondary: #2b3139;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;

// $container-max-widths: (
//   sm: 540px,
//   md: 100%,
//   lg: 100%,
//   xl: 100%,
//   xxl: 100%,
// );

@import "~bootstrap/scss/functions";
$link-color: shade-color($primary, 50%);

@import "~bootstrap/scss/bootstrap.scss";

* {
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-style: normal;
}
:root {
  --bs-body-bg: #181a1f;
}

// @tailwind base;
// @tailwind components;
@tailwind utilities;
